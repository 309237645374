import React from 'react'
import { useLottie } from "lottie-react";
import * as loadingFile from '../anim/loading.json';
import * as integrations from '../anim/integrations.json';
import * as integration from '../anim/integration.json';
import * as failed from '../anim/failed.json';
import * as login from '../anim/login.json';
import * as payment from '../anim/payment.json';
import * as database from '../anim/database.json';
import * as success from '../anim/success.json';
import * as errorcode from '../anim/error.json';
import * as thankyou from '../anim/thankyou.json';
import * as updates from '../anim/updates.json';
import * as phone from '../anim/phone.json';
import * as qrcode from '../anim/qrcode.json';

function Lotties({ file, title, description, width }) {

  function Lottie(props) {
    const options = {
      animationData: props.lottie.default,
      loop: true,
      autoplay: true,
      "aria-label": ""
    };

    const { View } = useLottie(options);
    return View;
  }

  return (
    <div className={`text-center align-middle m-auto w-fit h-fit`} style={{ width }}>
      <Lottie lottie={file} loop={true} />
      <span className="text-lg">{title}</span>
      <p><i>{description}</i></p>
    </div>
  )
}

export const AnimationLoading = (props) => <Lotties {...props} file={loadingFile} />

export const AnimationIntegration = (props) => <Lotties {...props} file={integrations} />

export const AnimationIntegrationSuccess = (props) => <Lotties {...props} file={integration} />

export const AnimationIntegrationFailed = (props) => <Lotties {...props} file={failed} />

export const AnimationLogin = (props) => <Lotties {...props} file={login} />

export const AnimationPayment = (props) => <Lotties {...props} file={payment} />

export const AnimationDatabase = (props) => <Lotties {...props} file={database} />

export const AnimationQrcode = (props) => <Lotties {...props} file={qrcode} />

export const AnimationSuccess = (props) => <Lotties {...props} file={success} />

export const AnimationError = (props) => <Lotties {...props} file={errorcode} />

export const AnimationThankyou = (props) => <Lotties {...props} file={thankyou} />

export const AnimationUpdates = (props) => <Lotties {...props} file={updates} />

export const AnimationPhone = (props) => <Lotties {...props} file={phone} />


export default Lotties
